import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionSubstance from 'components/SectionSubstance'
import PartnerCard from 'components/PartnerCard'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import Panorama from 'components/Panorama'
import classnames from 'classnames'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import { WebhotState } from '../tool/index.zh'
import { Languages } from 'i18n/config'
import * as styles from './index.module.less'

const WebhotLife: React.FC = (props) => {
  const demoUrl = ''
  const paradeUrl = ''
  const { webhotLifeYaml } = useStaticQuery(graphql`
    query {
      webhotLifeYaml {
        title
        description
        keywords
        software_title
        software_titleMb
        saTitle
        software_sectionCardList {
          desc
          title
          cover
        }
        software_buttonText
        software_qrcode
        panorama_title
        panorama_titleMb
        panorama_desc
        panorama_btnText
        panorama_btnCode
        panorama_imgUrl
        partner_desc
        partner_title
        partner_titleMb
        partner_imgBottomText
        partner_banner
        partner_bannerMb
        partner_dataList {
          imgUrl
        }
        partner_buttonText
        partner_qrcode
        cargo_title
        cargo_titleMb
        cargo_dataList {
          btnHref
          btnText
          dataMsg
          dataTitle
          imgUrl
          imgUrlMb
        }
        product_title
        product_titleMb
        product_dataList {
          btnHref
          btnText
          dataDsc
          dataMsg
          dataTitle
          imgUrl
          imgUrlMb
        }
        digitization_btnHref
        digitization_btnText
        digitization_desc
        digitization_orderHref
        digitization_orderText
        digitization_title
        banner_title
        banner_Desc
        banner_buttonHref
        banner_buttonText
        banner_qrcodeButtonText
        banner_sectionTwoDesc
        banner_sectionTwoTitle
        banner_sectionTwoTitleMb
        banner_icon
        banner_icon_mb
        banner_dataSource {
          buttonHref
          buttonText
          dataTitle
          descList
          qrcodeUrl
        }
        banner_list {
          isActive
          name
          url
        }
      }
    }
  `)

  return (
    <Layout {...props}>
      <WebhotState
        className={classnames(styles.webhotLife)}
        webhotYaml={webhotLifeYaml}
        slotPanoramaSection={
          <SectionSubstance
            titleTextAlignMb="left"
            className={classnames('bg-[#fff]', styles.webhotLifePanorama)}
            titleMb={webhotLifeYaml?.panorama_titleMb}
            title={webhotLifeYaml?.panorama_title}
            descText={webhotLifeYaml?.panorama_desc}
          >
            <Panorama
              className="panorama-container"
              btnText={webhotLifeYaml?.panorama_btnText}
              btnCode={webhotLifeYaml?.panorama_btnCode}
              imgUrl={webhotLifeYaml?.panorama_imgUrl}
            />
          </SectionSubstance>
        }
        slotPartnerSection={
          <SectionSubstance
            className={classnames(styles.webhotLifePartner)}
            titleTextAlignMb="left"
            title={webhotLifeYaml?.partner_title}
            titleMb={webhotLifeYaml?.partner_titleMb}
            descText={webhotLifeYaml?.partner_desc}
          >
            <PartnerCard className="lg:mt-[40px] mb-[20px]" dataSource={webhotLifeYaml?.partner_dataList} />
            <div className="text-center">
              <ScanCodeConsultButton
                imgBottomText={webhotLifeYaml?.partner_imgBottomText}
                buttonText={webhotLifeYaml?.partner_buttonText}
                shape="circle"
                qrcode={webhotLifeYaml?.partner_qrcode}
              />
            </div>
          </SectionSubstance>
        }
      />
    </Layout>
  )
}

export default WebhotLife
